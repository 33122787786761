// basic
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// animate
import 'animate.css'
import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

// fontawesome
import fontawesome from '@fortawesome/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import solid from '@fortawesome/fontawesome-free-solid'
import { faWeixin } from '@fortawesome/fontawesome-free-brands'
fontawesome.library.add(
  solid, faWeixin
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Element-ui
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
Vue.use(ElementUI)

// countdown
import VueCountDown from '@chenfengyuan/vue-countdown'
Vue.component(VueCountDown.name, VueCountDown)

// echarts
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core'
// 引入图表，图表后缀都为 Chart
import {
  BarChart
} from 'echarts/charts'
// 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  ToolboxComponent
} from 'echarts/components'
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import {
  CanvasRenderer
} from 'echarts/renderers'
// i18n
import i18n from '@/plugins/i18n'
// infinite-scroll

// 注册必须的组件
echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, LegendComponent, ToolboxComponent, BarChart, CanvasRenderer]
)
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
