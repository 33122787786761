<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {}
</script>
<style>
#app {
  margin: 0;
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2a3a4a;
  overflow-x: hidden;
}
</style>
