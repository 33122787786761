<template>
  <b-container fluid="true">
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1"
    >
    <!-- ========== HEADER ========== -->
    <header id="header">
      <div>
        <b-navbar
          toggleable="lg"
          type="light"
          variant="light"
          fixed="top"
          class="shadow-sm"
          :class="{
            'visible animate__animated animate__fadeInDown': showAnimationFor.navbar,
            'invisible animate__animated animate__fadeOutUp': !showAnimationFor.navbar
          }"
        >
          <b-navbar-brand href="#">
            <img
              src="../assets/images/logo/gytp.png"
              width="60"
              alt="logo"
            >
            SuperStarT
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse" />

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="text-center ml-auto">
              <b-nav-item @click="goAnchor('#intro')">
                <b-row>
                  <b-col cols="12"><font-awesome-icon icon="home" /></b-col>
                  <b-col cols="12">Home</b-col>
                </b-row>
              </b-nav-item>
              <b-nav-item @click="goAnchor('#about')">
                <b-row>
                  <b-col cols="12"><font-awesome-icon icon="align-justify" /></b-col>
                  <b-col cols="12">About</b-col>
                </b-row>
              </b-nav-item>
              <b-nav-item @click="goAnchor('#organizers')">
                <b-row>
                  <b-col cols="12"><font-awesome-icon icon="user-friends" /></b-col>
                  <b-col cols="12">Organizers</b-col>
                </b-row>
              </b-nav-item>
              <b-nav-item @click="goAnchor('#course')">
                <b-row>
                  <b-col cols="12"><font-awesome-icon icon="book" /></b-col>
                  <b-col cols="12">Online Course</b-col>
                </b-row>
              </b-nav-item>
              <b-nav-item @click="goAnchor('#sign-up')">
                <b-row>
                  <b-col cols="12"><font-awesome-icon icon="sign" /></b-col>
                  <b-col cols="12">SignUp</b-col>
                </b-row>
              </b-nav-item>
              <b-nav-item @click="goAnchor('#achievement')">
                <b-row>
                  <b-col cols="12"><font-awesome-icon icon="trophy" /></b-col>
                  <b-col cols="12">Achievement</b-col>
                </b-row>
              </b-nav-item>
              <b-nav-item @click="goAnchor('#partners')">
                <b-row>
                  <b-col cols="12"><font-awesome-icon icon="users" /></b-col>
                  <b-col cols="12">Partners</b-col>
                </b-row>
              </b-nav-item>
              <b-nav-item @click="jump('/live')">
                <b-row>
                  <b-col cols="12"><font-awesome-icon icon="user" /></b-col>
                  <b-col cols="12">Live Streamer</b-col>
                </b-row>
              </b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </header>
    <!-- ========== END HEADER ========== -->
    <!-- ========== MAIN CONTENT ========== -->
    <main id="content" role="main" class="bg-light">
      <!-- ========== HOME ========== -->
      <b-container fluid="true">
        <div
          id="intro"
          ref="intro"
          v-observe-visibility="{callback: (isVisible, entry) =>isNavViewableNow(isVisible, entry, 'navbar')}"
          class="banner-main py-lg-5"
        >
          <b-container class="text-center pt-5 align-items-center" fluid="true">
            <a class="navbar-brand ms-logo second my-lg-5" href="#">
              <b-img :src="require('../assets/images/logo/gytp.png')" alt="sphinx logo" width="300" class="img-responsive" title="sphinx" />
            </a>
            <div class="font-weight-bold">
              <h2 class="mb-5">Alibaba "Super StarT" <br>SAIL FROM HANGZHOU</h2>
              <p class="h3">
                The 2nd Global Youth Training Program of Cross-border E-Commerce Talent
              </p>
            </div>
          </b-container>
        </div>
      </b-container>
      <!-- ========== END HOME ========== -->
      <!-- ========== ABOUT SUPER START ========== -->
      <div id="about" class="py-2 py-lg-5">
        <b-row align-h="center" align-v="center" class="mt-5 mb-3">
          <font-awesome-icon icon="align-justify" size="3x" class="mr-3" />
          <h4>About Super StarT</h4>
        </b-row>
        <b-container fluid="true">
          <b-row class="mx-0">
            <b-col
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  isViewableNow(isVisible, entry, 'about1')
              }"
              lg="10"
              :class="{
                'visible animate__animated animate__bounceInLeft':
                  showAnimationFor.about1,
                invisible: !showAnimationFor.about1
              }"
            >
              <b-card no-body class="my-3">
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      :src="require('../assets/images/about/1.jpg')"
                      class="rounded-0"
                    />
                  </b-col>
                  <b-col md="8" class="mt-3 text-left">
                    <b-card-body
                      title="Alibaba Super StarT Program Official Release"
                    >
                      <b-card-text class="text-over">
                        Notification on ALIBABA SUPER START — the Second “Sail from Hangzhou” Global Youth Training
                        Program of Cross-Border E-Commerce Talents
                      </b-card-text>
                      <b-button
                        class="btn-color-orange"
                        variant="outline-primary"
                        href="https://mp.weixin.qq.com/s/AujUnFQ9S0oETCzQVXjVMw"
                        squared
                      >More Details</b-button>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  isViewableNow(isVisible, entry, 'about2')
              }"
              lg="10"
              offset-lg="2"
              :class="{
                'visible animate__animated animate__bounceInRight':
                  showAnimationFor.about2,
                invisible: !showAnimationFor.about2
              }"
            >
              <b-card no-body class="my-3">
                <b-row no-gutters>
                  <b-col md="4" order-lg="1">
                    <b-card-img
                      :src="require('../assets/images/about/2.jpg')"
                      class="rounded-0"
                    />
                  </b-col>
                  <b-col md="8" class="mt-3 text-left">
                    <b-card-body
                      title="Green Pass+Gifts?! Spokesperson For Alibaba Super StarT Wanted!"
                    >
                      <b-card-text class="text-over">
                        If your social media account has a certain number of followers<br>
                        If your friend is interested in cross-border e-commerce...<br>
                        No matter where you are in the world, you can sign up to be a “Super StarT” ambassador.
                      </b-card-text>
                      <b-button
                        class="btn-color-orange"
                        variant="outline-primary"
                        href="https://mp.weixin.qq.com/s/jISzKJzRh5FWOy4CS2z81Q"
                        squared
                      >More Details</b-button>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  isViewableNow(isVisible, entry, 'about3')
              }"
              lg="10"
              :class="{
                'visible animate__animated animate__bounceInLeft':
                  showAnimationFor.about3,
                invisible: !showAnimationFor.about3
              }"
            >
              <b-card no-body class="overflow-hidden my-3">
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      :src="require('../assets/images/about/3.jpg')"
                      class="rounded-0"
                    />
                  </b-col>
                  <b-col md="8" class="mt-3 text-left">
                    <b-card-body
                      title="Wanna Learn Alibaba’s E-Commerce Experience?Don't Miss This Web!"
                    >
                      <b-card-text class="text-over">
                        Today registration for 2nd batch already closed!<br>
                        BUT    Don't worry!!<br>
                        One last chance to sign up....
                      </b-card-text>
                      <b-button
                        class="btn-color-orange"
                        variant="outline-primary"
                        href="https://mp.weixin.qq.com/s/AAB0h35WTSqGWXgFVuOSug"
                        squared
                      >More Details</b-button>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  isViewableNow(isVisible, entry, 'about4')
              }"
              lg="10"
              offset-lg="2"
              :class="{
                'visible animate__animated animate__bounceInRight':
                  showAnimationFor.about4,
                invisible: !showAnimationFor.about4
              }"
            >
              <b-card no-body class="overflow-hidden my-3">
                <b-row no-gutters>
                  <b-col md="4" order-lg="1">
                    <b-card-img
                      :src="require('../assets/images/about/4.jpg')"
                      class="rounded-0"
                    />
                  </b-col>
                  <b-col md="8" class="mt-3 text-left">
                    <b-card-body title="Check Out Alibaba Super StarT First Batch Application Stats">
                      <b-card-text class="text-over">
                        Thanks to all, campus ambassadors, spokesperson, MyHomiers, your great effort has brought amazing
                        results to our first batch application!
                      </b-card-text>
                      <b-button
                        class="btn-color-orange"
                        variant="outline-primary"
                        href="https://mp.weixin.qq.com/s/83HAYcX994il4PR96VPSgQ"
                        squared
                      >More Details</b-button>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  isViewableNow(isVisible, entry, 'about5')
              }"
              lg="10"
              :class="{
                'visible animate__animated animate__bounceInLeft':
                  showAnimationFor.about5,
                invisible: !showAnimationFor.about5
              }"
            >
              <b-card no-body class="overflow-hidden my-3">
                <b-row no-gutters>
                  <b-col md="4">
                    <b-card-img
                      :src="require('../assets/images/about/5.jpg')"
                      class="rounded-0"
                    />
                  </b-col>
                  <b-col md="8" class="mt-3 text-left">
                    <b-card-body
                      title="Se Buscan Alibaba Super StarT Streamers. ¡Te Esperamos!"
                    >
                      <b-card-text class="text-over" />
                      <b-button
                        class="btn-color-orange"
                        variant="outline-primary"
                        href="https://mp.weixin.qq.com/s/LD_QIFDcfdYWQNqLI_TR3g"
                        squared
                      >More Details</b-button>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  isViewableNow(isVisible, entry, 'about6')
              }"
              lg="10"
              offset-lg="2"
              :class="{
                'visible animate__animated animate__bounceInRight':
                  showAnimationFor.about6,
                invisible: !showAnimationFor.about6
              }"
            >
              <b-card no-body class="overflow-hidden my-3">
                <b-row no-gutters>
                  <b-col md="4" order-lg="1">
                    <b-card-img
                      :src="require('../assets/images/about/6.jpg')"
                      class="rounded-0"
                    />
                  </b-col>
                  <b-col md="8" class="mt-3 text-left">
                    <b-card-body
                      title="Alibaba Super StarT Video!"
                    >
                      <b-card-text class="text-over" />
                      <b-button
                        class="btn-color-orange"
                        variant="outline-primary"
                        href="https://mp.weixin.qq.com/s/ewih2kOvQPMaENKvSaGTsg"
                        squared
                      >More Details</b-button>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <!-- ========== END ABOUT SUPER START ========== -->
      <!-- ========== ORGANIZERS ========== -->
      <div id="organizers" class="py-2 py-lg-5 bg-white">
        <b-row align-h="center" align-v="center" class="mt-5 mb-3">
          <font-awesome-icon icon="user-friends" size="3x" class="mr-3" />
          <h4>Organizers</h4>
        </b-row>
        <b-container>
          <b-row class="text-center" align-h="center" align-v="center">
            <b-col class="px-3 px-lg-3 py-3" cols="6" lg="3">
              <b-img fluid :src="require('../assets/images/logo/organizers/1.png')" />
            </b-col>
            <b-col class="px-3 px-lg-5 py-3" cols="6" lg="3">
              <b-img width="125" :src="require('../assets/images/logo/organizers/2.jpg')" />
            </b-col>
            <b-col class="px-3 px-lg-5 py-3" cols="6" lg="3">
              <b-img fluid :src="require('../assets/images/logo/organizers/3.png')" />
            </b-col>
            <b-col class="px-3 px-lg-5 py-3" cols="6" lg="3">
              <b-img fluid :src="require('../assets/images/logo/organizers/4.png')" />
            </b-col>
          </b-row>
        </b-container>
      </div>
      <!-- ========== END ORGANIZERS ========== -->
      <!-- ========== ONLINE COURSE ========== -->
      <div id="course" class="px-3 py-2 py-lg-5">
        <b-row align-h="center" align-v="center" class="mt-5 mb-3">
          <font-awesome-icon icon="book" size="3x" class="mr-3" />
          <h4>Online Course</h4>
        </b-row>
        <b-row class="text-left" align-v="center">
          <b-col lg="8">
            <template>
              <b-img :src="require('../assets/images/course/course_1.png')" fluid alt="course" />
            </template>
          </b-col>
          <b-col lg="4">
            <h4>Primary course</h4>
            <p class="mb-0">
              The primary course lays the foundation for participants with no knowledge of cross-border e-commerce,
              so as to deepen their understanding of cross-border e-commerce live streaming.
            </p>
          </b-col>
        </b-row>
        <el-divider />
        <b-row class="text-left" align-v="center">
          <b-col lg="8" order-lg="1">
            <template>
              <b-img :src="require('../assets/images/course/course_2.png')" fluid alt="course" />
            </template>
          </b-col>
          <b-col lg="4">
            <h4>Intermediate course</h4>
            <p class="mb-0">
              In the intermediate course, participants can go from China’s live streaming industry to cross-border
              live streaming industry, thus grasping the basics of cross-border live streaming, and understanding
              the characteristics of cross-border live streaming, the requirements of working as a live streamer, etc.
            </p>
          </b-col>
        </b-row>
        <el-divider />
        <b-row class="text-left" align-v="center">
          <b-col lg="8">
            <template>
              <b-img :src="require('../assets/images/course/course_3.png')" fluid alt="course" />
            </template>
          </b-col>
          <b-col lg="4">
            <h4>Advanced course</h4>
            <p class="mb-0">
              The advanced course focuses on the practice and summary of cross-border live streaming. This chapter
              combines the basic knowledge of marketing, advertising, consumer psychology, international trade
              theory, etc., and will strengthen the application of theoretical knowledge by participants.
            </p>
          </b-col>
        </b-row>
      </div>
      <!-- ========== END ONLINE COURSE ========== -->
      <!-- ========== SIGN UP ========== -->
      <div id="sign-up" class="py-2 py-lg-5 bg-white">
        <b-row align-h="center" align-v="center" class="mt-5 mb-3">
          <font-awesome-icon icon="sign" size="3x" class="mr-3" />
          <h4>Sign Up</h4>
        </b-row>
        <b-container class="mt-5">
          <b-row align-h="between">
            <b-col
              v-observe-visibility="{callback: (isVisible, entry) =>isViewableNow(isVisible, entry, 'signUp1')}"
              lg="5"
              :class="{'visible animate__animated animate__fadeInLeft':showAnimationFor.signUp1,
                       invisible: !showAnimationFor.signUp1}"
            >
              <b-img :src="require('../assets/images/post.jpg')" fluid alt="event" />
            </b-col>
            <b-col
              v-observe-visibility="{callback: (isVisible, entry) =>isViewableNow(isVisible, entry, 'signUp2')}"
              lg="6"
              :class="{'visible animate__animated animate__fadeInRight':showAnimationFor.signUp2,
                       invisible: !showAnimationFor.signUp2}"
            >
              <div class="h4 mt-5">Super Start(GYTP)</div>
              <p class="mt-5">
                The MOST popular program among international students recently!!!
              </p>
              <div class="mt-5 pt-5">
                <b-row class="mt-3 px-2">
                  <b-button v-b-modal.qr-modal pill block class="btn-orange" @click="modalToggle('Scan QR to Sign up', 1083)">
                    Live Streamers in China<font-awesome-icon class="ml-3" icon="arrow-circle-right" />
                  </b-button>
                </b-row>
                <b-row class="mt-3 px-2">
                  <b-button v-b-modal.qr-modal pill block variant="primary" class="btn-orange" @click="modalToggle('Scan QR to Sign up', 1085)">
                    Overseas Live Streamers<font-awesome-icon class="ml-3" icon="arrow-circle-right" />
                  </b-button>
                </b-row>
                <b-row class="mt-3 px-2">
                  <b-button v-b-modal.qr-modal pill block class="btn-orange" @click="modalToggle('Scan QR to Sign up', 1086)">
                    &ensp;&ensp;Entrepreneurship&ensp;&ensp;<font-awesome-icon class="ml-3" icon="arrow-circle-right" />
                  </b-button>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <!-- ========== END SIGN UP ========== -->
      <!-- ========== ACHIEVEMENT ========== -->
      <div id="achievement" class="py-2 py-lg-5">
        <b-container>
          <b-row align-h="center" align-v="center" class="mt-5 mb-3">
            <font-awesome-icon icon="trophy" size="3x" class="mr-3" />
            <h4>Achievement</h4>
          </b-row>
          <b-row>
            <b-col lg="3" cols="4">
              <b-img
                fluid
                rounded="lg"
                class="mt-3"
                :src="require('../assets/images/achievement/1.jpg')"
                alt="achievement"
              />
            </b-col>
            <b-col lg="3" cols="4">
              <b-img
                fluid
                rounded="lg"
                class="mt-3"
                :src="require('../assets/images/achievement/2.jpg')"
                alt="achievement"
              />
            </b-col>
            <b-col lg="3" cols="4">
              <b-img
                fluid
                rounded="lg"
                class="mt-3"
                :src="require('../assets/images/achievement/3.jpg')"
                alt="achievement"
              />
            </b-col>
            <b-col lg="3" cols="4">
              <b-img
                fluid
                rounded="lg"
                class="mt-3"
                :src="require('../assets/images/achievement/4.jpg')"
                alt="achievement"
              />
            </b-col>
            <b-col lg="3" cols="4">
              <b-img
                fluid
                rounded="lg"
                class="mt-3"
                :src="require('../assets/images/achievement/5.jpg')"
                alt="achievement"
              />
            </b-col>
            <b-col lg="3" cols="4">
              <b-img
                fluid
                rounded="lg"
                class="mt-3"
                :src="require('../assets/images/achievement/6.jpg')"
                alt="achievement"
              />
            </b-col>
            <b-col lg="3" cols="4">
              <b-img
                fluid
                rounded="lg"
                class="mt-3"
                :src="require('../assets/images/achievement/7.jpg')"
                alt="achievement"
              />
            </b-col>
            <b-col lg="3" cols="4">
              <b-img
                fluid
                rounded="lg"
                class="mt-3"
                :src="require('../assets/images/achievement/8.jpg')"
                alt="achievement"
              />
            </b-col>
            <b-col lg="3" cols="4">
              <b-img
                fluid
                rounded="lg"
                class="mt-3"
                :src="require('../assets/images/achievement/9.jpg')"
                alt="achievement"
              />
            </b-col>
            <b-col lg="3" cols="4">
              <b-img
                fluid
                rounded="lg"
                class="mt-3"
                :src="require('../assets/images/achievement/10.jpg')"
                alt="achievement"
              />
            </b-col>
            <b-col lg="3" cols="4">
              <b-img
                fluid
                rounded="lg"
                class="mt-3"
                :src="require('../assets/images/achievement/11.jpg')"
                alt="achievement"
              />
            </b-col>
            <b-col lg="3" cols="4">
              <b-img
                fluid
                rounded="lg"
                class="mt-3"
                :src="require('../assets/images/achievement/12.jpg')"
                alt="achievement"
              />
            </b-col>
          </b-row>
        </b-container>
      </div>
      <!-- ========== END ACHIEVEMENT ========== -->
      <!-- ========== PARTNERS ========== -->
      <div id="partners" class="py-2 py-lg-5 bg-white">
        <b-row align-h="center" align-v="center" class="mt-5 mb-3">
          <font-awesome-icon icon="users" size="3x" class="mr-3" />
          <h4>Partners</h4>
        </b-row>
        <b-container>
          <b-row>
            <b-col v-for="index in 29" :key="index" class="px-3 py-3" cols="3" lg="2" @click="jump(universityUrls[index-1])">
              <img class="img-fluid" :src="partnersLink(index)" alt="partners">
            </b-col>
          </b-row>
          <p class="text-center text-silver mt-5">
            <b class="text-dark">Note:</b>
            The list is in no particular order. Click a university's logo to visit the official website of its
            international college (department).
          </p>
        </b-container>
      </div>
      <!-- ========== END PARTNERS ========== -->
    </main>
    <!-- ========== END MAIN CONTENT ========== -->
    <!-- ========== FOOTER ========== -->
    <footer class="bg-dark pt-5 text-silver">
      <div class="pb-1 pt-lg-2 text-left">
        <b-row class="mt-5 mx-0" align-h="center">
          <!-- ========== LOGO========== -->
          <b-col lg="3" class="mb-5 mb-lg-0">
            <router-link to="/">
              <img
                src="../assets/images/logo/gytp.png"
                width="150"
                alt="logo"
              >
            </router-link>
          </b-col>
          <!-- ========== END LOGO ========== -->
          <!-- ========== ACCOUNT ========== -->
          <b-col lg="4" class="mb-5 mb-lg-0">
            <h3>Social Media</h3>
            <ul class="list-unstyled">
              <b-row align-h="start">
                <b-col md="7">
                  <li
                    v-b-modal.qr-modal
                    class="my-4 pointer"
                    @click="modalToggle('Scan QR to follow us', 'wechat_official')"
                  >
                    <img
                      class="mr-3"
                      width="32"
                      src="../assets/images/icon/wechat_subscriptions.svg"
                      alt="wechat"
                    >Wechat Subscriptions
                  </li>
                  <li
                    v-b-modal.qr-modal
                    class="my-4 pointer"
                    @click="modalToggle('Scan QR to follow us', 'wechat_person')"
                  >
                    <img
                      class="mr-3"
                      width="32"
                      src="../assets/images/icon/wechat.svg"
                      alt="wechat"
                    >Personal Account
                  </li>
                  <li
                    v-b-modal.qr-modal
                    class="my-4 pointer"
                    @click="modalToggle('Scan QR to follow us', 'wechat_video')"
                  >
                    <img
                      class="mr-3"
                      width="32"
                      src="../assets/images/icon/wechat_channels.svg"
                      alt="wechat"
                    >Wechat Channels
                  </li>
                  <li
                    class="my-4 pointer"
                    @click="jump('https://vm.tiktok.com/ZSweE1Yw/')"
                  >
                    <img
                      class="mr-3"
                      width="32"
                      src="../assets/images/icon/external_ tiktok.svg"
                      alt="wechat"
                    >TikTok
                  </li>
                </b-col>
                <b-col md="5">
                  <li
                    class="my-4 pointer"
                    @click="
                      jump('https://www.instagram.com/alibaba_superstart/')
                    "
                  >
                    <img
                      class="mr-3"
                      width="32"
                      src="../assets/images/icon/instagram.svg"
                      alt="wechat"
                    >Instagram
                  </li>
                  <li
                    class="my-4 pointer"
                    @click="
                      jump('https://www.facebook.com/alibaba.superstart')
                    "
                  >
                    <img
                      class="mr-3"
                      width="32"
                      src="../assets/images/icon/facebook.svg"
                      alt="wechat"
                    >Facebook
                  </li>
                  <li
                    class="my-4 pointer"
                    @click="
                      jump(
                        'https://www.linkedin.com/in/alibaba-superstart-235a3a204/'
                      )
                    "
                  >
                    <img
                      class="mr-3"
                      width="32"
                      src="../assets/images/icon/ins.svg"
                      alt="wechat"
                    >LinkedIn
                  </li>
                </b-col>
              </b-row>
            </ul>
          </b-col>
          <!-- ========== END ACCOUNT ========== -->
          <!-- ========== COMPANY INFO ========== -->
          <b-col lg="4" class="mb-5 mb-lg-0">
            <h3>Contact Us</h3>
            <ul class="list-unstyled">
              <li class="media my-4">
                <font-awesome-icon icon="user" size="lg" class="mr-2" />
                <div class="media-body">
                  DengYahui, WangRong
                </div>
              </li>
              <li class="media my-4">
                <font-awesome-icon icon="phone" size="lg" class="mr-2" />
                <div class="media-body">
                  17816876961, 0571-85250381
                </div>
              </li>
              <li class="media my-4">
                <font-awesome-icon icon="envelope" size="lg" class="mr-2" />
                <div class="media-body">
                  superstart_gytp@163.com
                </div>
              </li>
              <li class="media my-4">
                <font-awesome-icon
                  icon="location-arrow"
                  size="lg"
                  class="mr-2"
                />
                <div class="media-body">
                  1623, Block E, CitizenCenter, No.18, Jiefang East Road,
                  Jianggan District, Hangzhou, Zhejiang, China
                </div>
              </li>
              <li class="media my-4">
                <font-awesome-icon
                  icon="envelope-open"
                  size="lg"
                  class="mr-2"
                />
                <div class="media-body">
                  310026
                </div>
              </li>
            </ul>
          </b-col>
          <!-- ========== END COMPANY INFO ========== -->
        </b-row>
      </div>
      <hr class="bg-secondary">
      <!-- ========== COPYRIGHT ========== -->
      <div class="text-center text-white-50 mb-3">©2021 弧米科技
        <a href="https://beian.miit.gov.cn/" class="text-white-50" target="_blank">
          浙ICP备17057855号-3
        </a>
      </div>
      <!-- ========== END COPYRIGHT ========== -->
      <hr class="opacity-xs my-0">
    </footer>
    <!-- ========== END FOOTER ========== -->

    <!-- QR MODAL -->
    <b-modal id="qr-modal" :title="modalTitle" hide-footer>
      <div class="text-center">
        <img class="" :src="qrPath" alt="qr">
      </div>
    </b-modal>
    <!-- END QR MODAL -->
  </b-container>
</template>
<script>
// @ is an alias to /src
import '../assets/css/base.css'

export default {
  name: 'Home',
  data() {
    return {
      qrPath: '',
      modalTitle: '',
      slide: 0,
      sliding: null,
      screenHeight: 0,
      showAnimationFor: {
        navbar: false,
        about1: false,
        about2: false,
        about3: false,
        about4: false,
        about5: false,
        about6: false,
        signUp1: false,
        signUp2: false
      },
      universityUrls: [
        'http://iczu.zju.edu.cn/',
        'http://sie.whu.edu.cn/',
        'http://sie.hust.edu.cn/',
        'http://sie.xjtu.edu.cn/',
        'http://sie.tju.edu.cn/',
        'http://www2.scut.edu.cn/sie_cn/main.htm',
        'http://is.buaa.edu.cn/',
        'http://npuinternationalcollege.nwpu.edu.cn/',
        'https://isc.bit.edu.cn/',
        'http://oec.ujs.edu.cn/',
        'https://cie.shu.edu.cn/',
        'http://cice.ccnu.edu.cn/',
        'https://www.gjxy.zjut.edu.cn/index.php/en/home',
        'https://iec.cug.edu.cn/',
        'https://lxs.szu.edu.cn/index.htm',
        'http://gjxy.zjnu.edu.cn/',
        'http://international.swufe.edu.cn/',
        'http://admission.zstu.edu.cn/en/',
        'http://sie.uibe.edu.cn/',
        'http://iao.shnu.edu.cn/',
        'http://sie.hznu.edu.cn/Portal_CN/Pages/Default.aspx',
        'http://sie.zjgsu.edu.cn/',
        'https://iec.zafu.edu.cn/index.htm',
        'http://www.oisa.shisu.edu.cn/',
        'https://sie.zufe.edu.cn/',
        'https://ies.zust.edu.cn/zdsy.htm',
        'http://cie.zisu.edu.cn/',
        'https://www.xjtlu.edu.cn/zh/',
        'https://www.nottingham.edu.cn/cn/'
      ]
    }
  },
  created() {
    this.screenHeight = document.documentElement.clientHeight
  },
  methods: {
    modalToggle(title, filename) {
      this.modalTitle = title
      this.qrPath = require('../assets/images/QR/QR_' + filename + '.png')
    },
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    goAnchor(selector) {
      document.querySelector(selector).scrollIntoView({
        behavior: 'smooth'
      })
    },
    isViewableNow(isVisible, entry, section) {
      if (isVisible === false) {
        return
      }
      this.showAnimationFor[section] = isVisible
    },
    isNavViewableNow(isVisible, entry, section) {
      this.showAnimationFor[section] = !isVisible
    },
    jump(link) {
      window.location.href = link
    },
    partnersLink(index) {
      return require('@/assets/images/logo/college/' + index + '.jpg')
    }
  }
}
</script>
