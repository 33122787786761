import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Log in'
    },
    component: () => import('@/views/login')
  },
  {
    path: '/exam',
    meta: {
      title: 'Exam'
    },
    component: () => import('@/views/exam/index'),
    children: [
      {
        path: '',
        component: () => import('@/views/exam/components/Home')
      },
      {
        path: 't',
        component: () => import('@/views/exam/components/ExamList')
      }
    ]
  },
  {
    path: '/statistics',
    meta: {
      title: '超能国际生—“扬帆起杭”第二届全球跨境电商创业创新大赛实时报名数据图'
    },
    component: () => import('@/views/statistics/index')
  },
  {
    path: '/live',
    meta: {
      title: 'Live'
    },
    component: () => import('@/views/hosts/index'),
    children: [
      {
        path: '',
        component: () => import('@/views/hosts/components/HostsList')
      },
      {
        path: 'details',
        component: () => import('@/views/hosts/components/Details')
      },
      {
        path: 'upload',
        component: () => import('@/views/hosts/components/upload')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
